import React, {forwardRef} from 'react';
import * as PropTypes from "prop-types";
import BSButton from 'react-bootstrap/Button';
import styled from "styled-components";

const color = ({color, theme, variant}) => {
    if (!variant.includes('outline')) return theme.palette.common.white;
    return theme.palette[color] ? theme.palette[color].main : ''
};

const border = ({color, theme, variant}) => {
    // if (!variant.includes('outline')) return theme.palette.common.white;
    return theme.palette[color] ? theme.palette[color].main : ''
};
const backgroundColor = ({color, theme, variant}) => {
    if (variant.includes('outline')) return theme.palette.common.white;
    if (theme.palette[color]) return theme.palette[color].main;
    return '';
};

const colorHov = ({color, theme,variant}) => {
    if (variant.includes('outline')) {
        return theme.palette.common.black;
    }
    return theme.palette.common.white;
};

const borderOrBackgroundHov = ({color, theme}) => {
    if (theme.palette[color]) return theme.palette[color].main;
    return ''
};
const borderOrBackgroundActive = ({color, theme}) => {
    if (theme.palette[color]) return theme.palette[color].dark;
    return ''
};


const StyledButton = styled(BSButton)`
&&.button-custom{ 
    background-color: ${backgroundColor};
    border-color: ${border};
    color: ${color};
    :hover{
        background-color: ${borderOrBackgroundHov};
        border-bottom-color: ${borderOrBackgroundHov};
        border-top-color: ${borderOrBackgroundHov};
        border-right-color: ${borderOrBackgroundHov};
        border-left-color: ${borderOrBackgroundHov};
        color: ${colorHov};
    }   
    :not(:disabled):not(.disabled):active:focus{
        box-shadow: none;
    }
    :not(:disabled):not(.disabled):active{
        background-color: ${borderOrBackgroundActive};
        border-color: ${borderOrBackgroundActive};
        color: ${colorHov};
    }
}
`;

function Button({variant = '', color = 'primary', children, className, ...props}, ref) {
    const bootstrapVariant = (variant ? variant + '-' : '') + color;
    return (<StyledButton
        className={'button-custom ' + className}
        variant={bootstrapVariant}
        color={color}
        {...props}
        ref={ref}
    >
        {children}
    </StyledButton>)
}
Button = forwardRef(Button);
Button.propTypes = {
    variant: PropTypes.string,
};

export default Button;