import React from "react";
import {useTranslation} from "react-i18next";
import Map from "./Map/index";
import styled from "styled-components";
import {connect} from "react-redux";
import {closeDialog} from "../store/actionCreators/general";

import Loader from "./Loaders/Loader";
import useInitApp from "../Hooks/useInitApp";
import MapControls from "./MapControls/MapControls";
import {ifShow} from "../controller/common";
import {setProjects, showCostsTable} from "../store/actionCreators/map";
import {useAlertFirstRender, useFetchLoader} from "../Hooks/common";
import FullScreenLoader from "./Common/FullScreenLoader";

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  position: relative;
  flex-grow: 1;
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    // extract to zomm component !
    right: 275px !important; // extract to zomm component !
    bottom: 82px !important; // extract to zomm component !
  }
  //.gm-style > div:nth-child(3) > .gmnoprint{
  //  right:0 !important;
  //  top :0 !important;
  //  left: unset !important;
  //}
  button.gm-control-active.gm-fullscreen-control {
    right: 177px !important;
    top: 0 !important;
  }
`;
const H1 = styled.h1`
  text-align: center;
  width: 100%;
  padding-top: 20px;
  color: #000;
`;

const Div = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${({theme}) => theme.palette.grey[300]};
`;

function Home({isCostsTable, selectedHeights, showCostsTable, user}) {
  const {fetching, userFetched, projectFetched, requireLogin} = useInitApp();
  const notLoggedIn = ifShow(!user.isAuthenticated && !fetching && userFetched);
  const [isFetchLoader] = useFetchLoader();
  // useAlertFirstRender('firstrender Home');
  return (
    <Container>
      {ifShow(fetching) && (
        <Div>
          {" "}
          <Loader />
        </Div>
      )}
      {user.isAuthenticated && <Map />}
      <MapControls isCostsTable={isCostsTable} projectFetched={projectFetched} requireLogin={requireLogin} />
      {notLoggedIn && <H1>please Sign-in!</H1>}
      {isFetchLoader && <FullScreenLoader />}
    </Container>
  );
}

function mapStateToProps(reduxState) {
  return {
    displayedSectors: reduxState.map.selectedHeights,
    isCostsTable: reduxState.general.isCostsTable,
    user: reduxState.user,
    zoom: reduxState.map.mapZoom,
  };
}

export default connect(mapStateToProps, {closeDialog, showCostsTable, setProjects})(Home);
