import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from "react";
import {fetchAuth, handleError, navigateResponse} from "../../controller/ApiManager/helper";
import Api from "../../controller/ApiManager/index";

// קומפוננטה זו אינה אפויה! אנא פנו לנעם התותח על חלל
function ImageUploaderBtn(props, ref) {
  const inputRef = useRef(null);
  const [uploadTrigger1, setUploadTrigger1] = useState(false); // אולי טריגר אחד לשניהם כלומר אותו טריגר?
  const [uploadTrigger2, setUploadTrigger2] = useState(false);
  useEffect(() => {
    const image = inputRef.current.files[0];
    const name = image?.name;
    if (uploadTrigger1 || (uploadTrigger2 && image)) {
      handleUploadImageToS3(image);
      setUploadTrigger1(false);
      setUploadTrigger2(false);
    }
  }, [uploadTrigger1, uploadTrigger2]);

  useImperativeHandle(ref, () => ({
    uploadImageToMemory,
    uploadImageToS3,
    uploadImage,
  }));

  function uploadImage() {
    uploadImageToMemory();
    uploadImageToS3();
  }
  function uploadImageToMemory() {
    inputRef.current?.click();
  }
  function uploadImageToS3() {
    setUploadTrigger2(true);
  }
  function handlOnChange() {
    setUploadTrigger1(true);
  }

  async function handleUploadImageToS3(image, name) {
    try {
      if (image.size > 1000000) throw Error("It is not possible to upload an image above 10 MB");
      const response = await Api.uploadImage(image, "imageTestNEW1.jpg");
      console.log("handleUploadFile after upload! ");
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <input
      ref={inputRef}
      // onChange={()=>handleUploadFile(inputRef.current.files[0])}
      onClick={(event) => (event.target.value = null)}
      onChange={handlOnChange}
      type="file"
      className="d-none"
      accept="image/*"
    />
  );
}

export default forwardRef(ImageUploaderBtn);
