import {useEffect, useRef} from "react";
import {findSectorId} from "../controller/dataOperations";
import Api from "../controller/ApiManager";
import {mapPrediction, mergePredictions} from "../controller/binMappers";
import {spreadPlacements} from "../controller/useEffectCondition";
import {useAddToast, useFetchLoader, usePredictionState} from "./common";
import {useCorrectionFactorCalculator} from "./useCorrectionFactor";
import useProject from "./useProject";
import {useDisplayedAntennas} from "./displayedSectors";

function getSiteBinDetails(project, siteId, heightMeters, antennaType) {
  const site = project.sites.find(({_id}) => _id === siteId);
  const location = site.location;
  const siteName = site.displayName;
  return {location, site: siteName, height: heightMeters, smartType: antennaType};
}

function useMapPrediction({setBinsArray, mapBins, sites}) {
  const project = useProject();
  const calcCorrectionFactor = useCorrectionFactorCalculator();
  const fetchPrediction = useRef(false);
  const displayedAntennas = useDisplayedAntennas();
  const [, setFetchLoader] = useFetchLoader();
  const [predictionState, setPredictionState] = usePredictionState();
  const addToast = useAddToast();

  function setFetchPrediction(bool) {
    fetchPrediction.current = bool;
  }

  useEffect(() => {
    if (fetchPrediction.current) return;
    if (predictionState) {
      setFetchPrediction(true);
      setBinsArray([]); // clining binsarry
      setFetchLoader(true);
      const promises = displayedAntennas
        .filter((ds) => ds.display)
        .map(async ({siteId, sectorId, antennaModel}) => {
          const {prediction} = await Api.Sector.predict(siteId, sectorId, antennaModel);

          const site = project.sites.find(({_id}) => _id === siteId);
          const sectors = site.preDesign.sectors.concat(site.postDesign.sectors);
          const sector = sectors.find(({_id}) => _id === sectorId);
          return {siteId, sector, antennaModel, prediction};
        });
      Promise.all(promises)
        .then((data) => {
          let maxAmount = -Infinity;
          for (const amount of data.map(({recommendedAmount}) => recommendedAmount)) {
            if (amount && maxAmount < amount) {
              maxAmount = amount;
            }
          }
          if (maxAmount && maxAmount !== -Infinity) {
            alert("please change bin resolution higher then " + maxAmount + " in order for prediction to work!");
            setPredictionState(false);
            return;
          }
          const binsPerSite = data.map(({siteId, sector, heightMeters, antennaModel, prediction}) => {
            const siteDetails = getSiteBinDetails(project, siteId, heightMeters, antennaModel);
            const {txPower, txLoss} = sector;
            const correctionFactor = calcCorrectionFactor({antennaType: antennaModel, txPower, txLoss});
            const bins = mapPrediction(prediction, siteDetails, correctionFactor);
            return {bins, siteId, sectorId: sector._id, antennaModel};
          });
          let bins = mergePredictions(binsPerSite);
          setBinsArray(bins);
        })
        .catch((err) => {
          console.error(err);
          addToast({body: "Error Receiving Prediction From Server", error: true});
        })
        .finally(() => {
          setFetchPrediction(false);
          setFetchLoader(false);
        });
    } else {
      setBinsArray(mapBins());
    }
  }, [predictionState, spreadPlacements(sites), calcCorrectionFactor, displayedAntennas]);
}

export default useMapPrediction;
