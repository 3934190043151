import {useEffect, useMemo, useState} from "react";
import {SET_COVERAGE} from "../store/actionTypes";
import {getSiteSectors, isPointInPolygon} from "../controller/dataOperations";
import useDisplayMapDetails from "./useDisplayMapDetails";
import {openDialog} from "../store/actionCreators/general";
import Constants from "../controller/Constants";
import useProject, {useProjectParams} from "./useProject";
import {rssiToBitRate} from "../controller/mapper";
import {useFetchLoader, usePredictionState, usePrevious} from "./common";
import {useDispatch, useSelector} from "react-redux";
import {useCorrectionFactorCalculator} from "./useCorrectionFactor";
import GrahamScan from "@lucio/graham-scan";
import useMapPrediction from "./useMapPrediction";
import {ifFuncExec} from "../controller/common";
import {useDisplayedAntennasActions} from "./displayedSectors";

const {
  mapStates: {AREA_SELECTION},
} = Constants;
const isGraham = false;

function checkNotifyClustering(chosenSites, displayedSectors, project, binsArray, loadingPrediction) {
  const activeDisplayedSectors = displayedSectors.filter((displayedSector) => displayedSector.display);
  const isChosenSites = Boolean(chosenSites);
  const minimumSites = isChosenSites ? 2 : 1;
  const selectedSites = activeDisplayedSectors.map((obj) => obj.siteId);
  const sitesExist = selectedSites.map((siteId) => Boolean(project.sites.find((site) => siteId === site._id))).every((_) => _);
  const isSitesExistAndEnough = selectedSites.length >= minimumSites ? sitesExist : false;
  const shouldNotifyClustering =
    isSitesExistAndEnough &&
    activeDisplayedSectors.filter((displayedSector) => displayedSector.sectorId).length > 0 &&
    binsArray.length === 0 &&
    !loadingPrediction;
  return shouldNotifyClustering;
}

function isHasBinsPlacements(project) {
  const allSectors = project.sites.flatMap(getSiteSectors);
  return allSectors.find((sector) => sector.binsPlacements.length > 0)?.binsPlacements;
}

function useMapContentEffects({map, polygonPath, binsMapper, sites: stateSites, chosenSites}) {
  const calcCorrectionFactor = useCorrectionFactorCalculator();
  const project = useProject();
  const sites = project.sites.filter((siteI) => stateSites.some((sSite) => sSite._id === siteI._id));
  const dispatch = useDispatch();
  const isAreaSelection = useSelector((state) => state.map.mapState) === AREA_SELECTION;
  const {displayedAntennas, resetDisplayedAntennas} = useDisplayedAntennasActions();
  const projectParams = useProjectParams();
  const {rssiThreshold, mapLayer, channelBW} = projectParams;
  const isBitRate = ["Bit Rate - Coverage Optimized", "Bit Rate - Capacity Optimized"].includes(mapLayer);
  const [predictionState] = usePredictionState();
  const [isFetchLoader] = useFetchLoader();
  const [binsArray, setBinsArray] = useState(mapBins);
  // const prevProject = usePrevious(project);
  // const prevProject = usePrevious(project);
  useEffect(() => {
    map.setOptions({draggable: !isAreaSelection});
  }, [isAreaSelection]);

  function filterBins(bins) {
    if (isGraham && predictionState) {
      const points = mapBins().map(({location}) => [location.lat, location.lng]);
      const grahamScan = new GrahamScan();
      grahamScan.setPoints(points);
      const polygon = grahamScan.getHull();
      bins = bins.filter((bin) => isPointInPolygon([bin.location.lat, bin.location.lng], polygon));
    }
    if (polygonPath.length > 2) {
      const polygonPathFlat = polygonPath.map((point) => [point.lat, point.lng].map(ifFuncExec));
      bins = bins.filter((bin) => isPointInPolygon([bin.location.lat, bin.location.lng], polygonPathFlat));
    }
    return bins;
  }

  function mapBins() {
    const bins = binsMapper(sites, displayedAntennas, calcCorrectionFactor, mapLayer);
    return bins;
  }

  const data = useMapPrediction({setBinsArray, sites, mapBins}); //?

  const filteredBins = useMemo(() => filterBins(binsArray), [binsArray, predictionState, polygonPath]);

  const mapStats = useDisplayMapDetails({binsArray: filteredBins});

  useEffect(() => {
    // calc coverage when sectors changed
    function calcCoverageForBin(accm, binObject) {
      if (!isBitRate && binObject.signal >= rssiThreshold) {
        return accm + 1;
      }
      if (!isBitRate) return accm;
      const {smartType} = binObject.sites.reduce((acc, b) => (acc.signal > b.signal ? acc : b));
      if (isBitRate && rssiToBitRate(binObject.signal, {smartType, channelBW}) >= rssiThreshold) {
        return accm + 1;
      }
      return accm;
    }

    const thresholdBins = filteredBins.reduce(calcCoverageForBin, 0);
    const coverage = (thresholdBins / filteredBins.length) * 100;
    dispatch({type: SET_COVERAGE, coverage});
  }, [project, displayedAntennas, mapLayer, rssiThreshold, channelBW, filteredBins, polygonPath]);

  useEffect(() => {
    // update bins when mapLayer changes
    const someBinsPlacements = isHasBinsPlacements(project);
    if (!someBinsPlacements) {
      resetDisplayedAntennas();
      return;
    }
    const placementSameAsMapLayer = someBinsPlacements && someBinsPlacements[0].mapLayer === project.currentMapLayer;
    if (!placementSameAsMapLayer) return;
    const bins = mapBins();
    setBinsArray(bins);
  }, [project]);

  useEffect(() => {
    // bins check to notify user about clustering. and open the 'rearrange bins' dialog
    const timeout = setTimeout(() => {
      const shouldNotifyClustering = checkNotifyClustering(chosenSites, displayedAntennas, project, binsArray, isFetchLoader);
      if (shouldNotifyClustering) dispatch(openDialog(Constants.modals.CLUSTER_ALERT));
    }, 200);
    return () => clearTimeout(timeout);
  }, [project, displayedAntennas, binsArray, project.currentMapLayer]);
  return {...mapStats, binsArray: filteredBins, loadingPrediction: isFetchLoader};
}

export default useMapContentEffects;
