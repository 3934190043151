import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import {
  colorMapper,
  colorMapper_new,
  colorMapper_new_Latency,
  getLayerType,
  getRssiLevels,
  getUnitsForSignal,
  mapBitRateMult,
} from "../../controller/mapper";
import {sortSmartTypeString} from "../../controller/dataOperations";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import useProject, {useProjectParams} from "../../Hooks/useProject";
import Constants, {MINISTRY_OF_ENVIRONMENTAL_PROTECTION, colorsLabelsInfo} from "../../controller/Constants";
import {mapLayers} from "../../controller/Constants";
import Validator from "../../controller/Validator";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors";
import {isLight} from "../../controller/common";

const ColoredListItem = styled(ListGroup.Item)`
  color: ${(props) => (isLight(props.backcolor) ? "black" : "white")};
  font-weight: bold;
  //text-align: center;
  //text-shadow: 0 0 2px rgba(0,0,0,1), 1px 1px 0 rgba(0,0,0,0.7), -1px -1px 0 rgba(0,0,0,0.2) ;
  background-color: ${(props) => (props.backcolor ? props.backcolor + " !important" : "red")};
  &.list-group-item {
    && {
      border-radius: 0;
    }
    padding: 2px 4px;
    font-size: 12px;
  }
`;
const StyledModal = styled(Modal.Dialog)`
  &.modal-dialog {
    @media (min-width: 576px) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 0;
    border: none; //${({theme}) => theme.palette.primary.main};
    box-shadow: ${({theme}) => theme.shadows.map.control};
    .list-group-colors {
      border-radius: 0;
      min-width: 100px;
    }
  }
  .modal-header {
    padding: 7px 16px;
    background-color: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.common.white};
    border-radius: 0;
    .h4.modal-title {
      font-size: 15px;
    }
  }
`;
const ControlContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin: 0;
`;
const {RSRP_COVERAGE, RSRP_CAPACITY, RSRQ_CAPACITY, RSRQ_COVERAGE, DATA_RATE_UPL, DATA_RATE_DNL, LATENCY, RSRP, RSRQ, SNR, CQI} = mapLayers; ///////////////////////////////////////////////  משנה גם פה

function getColorLabels(mapType, mapLayer, rssiLevels, t, selectedSmartType, channelBW) {
  let valueName = "RSSI";
  if (mapType === Constants.mapTypes.C2I) {
    valueName = "c/i";
  }
  if ([RSRP_COVERAGE, RSRP_CAPACITY].includes(mapLayer)) valueName = "RSRP";
  if ([RSRQ_COVERAGE, RSRQ_CAPACITY].includes(mapLayer)) valueName = "RSRQ";
  if (DATA_RATE_DNL === mapLayer) valueName = "Download";
  if (DATA_RATE_UPL === mapLayer) valueName = "Upload";
  if (LATENCY === mapLayer) valueName = mapLayer;
  if (RSRP === mapLayer) valueName = mapLayer;
  if (RSRQ === mapLayer) valueName = mapLayer;
  if (CQI === mapLayer) valueName = mapLayer;
  if (SNR === mapLayer) valueName = mapLayer; ///////////////////////////////////////////////  משנה גם פה
  const layerType = getLayerType(mapLayer);
  // debugger;
  const signalGenerator = (rssiValue, valueName, valueUnits, i, array) => {
    if (rssiValue !== Infinity) return ` ${i < array.length - 1 ? `${array[i + 1]} < ` : ""}${valueName} < ${rssiValue}`;
    return `${array[1]} < ${valueName}`;
  };
  const signalGenerator2 = (rssiValue, valueName, valueUnits, i, array) => {
    if (rssiValue !== Infinity)
      return ` ${i < array.length - 1 ? `${array[i + 1]} ${valueUnits} < ` : ""}${valueName} < ${rssiValue} ${valueUnits}`;
    return `${array[1]} ${valueUnits} < ${valueName}`;
  };
  const signalGeneratorLatency = (rssiValue, valueName, valueUnits, i, array) => {
    if (rssiValue !== Infinity) {
      let res = ` ${i < array.length - 1 ? `${array[i + 1]} ${valueUnits} < ` : ""}${valueName} < ${rssiValue} ${valueUnits}`;
      if (i == 4) return `${valueName} > ${rssiValue} ${valueUnits}`;
      return res;
    }
    return `${array[1]} ${valueUnits} > ${valueName}`;
  };
  const signalGeneratorCQI = (rssiValue, valueName, valueUnits, i, array) => {
    const labels = ["64QAM", "64QAM", "16QAM", "QPSK", "QPSK"];
    return labels[i];
  };
  const labelGenerator = {
    ///////////////////////////////////////////////  משנה גם פה
    "": () => t("loading_cm "),
    RSSI: signalGenerator,
    MCS: (rssiValue, valueName, i, array) =>
      t(["No_Coverage_Mcs2", "QPSK_0.1", "QPSK_0.5", "16QAM_0.5", "64QAM_0.5", "64QAM_0.9"][array.length - i - 1].replace(".", "_")),
    Bit_Rate: (rssiValue, valueName, i, array) => {
      let stringsList =
        channelBW === 20
          ? ["No_Coverage_Br", "2.3_Mbps", "10_Mbps", "18.6_Mbps", "29.4_Mbps", "54_Mbps"]
          : ["No_Coverage_Br", "6.8_Mbps", "30_Mbps", "55.8_Mbps", "88.3_Mbps", "162_Mbps"];
      const bitRateMult = mapBitRateMult(selectedSmartType);
      if (mapLayer === mapLayers.BIT_RATE_CAPACITY && selectedSmartType) {
        stringsList = stringsList.map((label) => {
          const num = label.split("_")[0];
          if (!isNaN(num)) return `${(Number.parseFloat(num) * bitRateMult).toFixed(1)}_Mbps`;
          return label;
        });
      }
      return t(stringsList[array.length - i - 1].replace("_", " "));
    },
    RSRP: signalGenerator2,
    RSRQ: signalGenerator2,
    // RSRP_NEW: signalGenerator, ////////////////////////////// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    // RSRQ_NEW: signalGenerator, ////////////////////////////// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    "Data rate - DNL": signalGenerator2,
    "Data rate - UPL": signalGenerator2,
    Latency: signalGeneratorLatency,
    SNR: signalGenerator2,
    CQI: signalGeneratorCQI,
  };
  // debugger;
  return rssiLevels.map((rssiValue, i, array) => ({
    rssiValue,
    label: labelGenerator[layerType](rssiValue, valueName, getUnitsForSignal(valueName), i, array),
    color: ["Data rate - UPL", "Data rate - DNL", "RSRP", "RSRQ", "Latency", "SNR", "CQI"].includes(layerType) ///////////////////////////////////////////////  משנה גם פה
      ? layerType === "Latency"
        ? colorMapper_new_Latency(rssiValue, rssiLevels.slice(1))
        : colorMapper_new(rssiValue, rssiLevels.slice(1)) //colorMapper_new
      : colorMapper(rssiValue, rssiLevels.slice(1)),
  }));
}

function ColorMapping({mapType}) {
  const project = useProject();
  const projectParams = useProjectParams();
  const {t} = useTranslation();
  const layerType = getLayerType(projectParams.mapLayer);
  const displayedAntennas = useDisplayedAntennas();
  const isPowerDensity = project.name === MINISTRY_OF_ENVIRONMENTAL_PROTECTION;
  const colorTitle = {
    RSSI: t("RSSI_Level"),
    MCS: t("MCS_Level"),
    Bit_Rate: t("Bit_Rate_Level"),
    RSRP: t("RSRP_Level"),
    RSRQ: t("RSRQ_Level"),
    "Data rate - DNL": "Download", /////////////////////////////////////////////////////////////// <<
    "Data rate - UPL": "Upload", /////////////////////////////////////////////////////////////// <<
    Latency: "Latency", /////////////////////////////////////////////////////////////// <<
    SNR: "SNR", /////////////////////////////////////////////////////////////// <<
    CQI: "CQI", ///////////////////////////////////////////////  משנה גם פה
  };
  try {
    const {mapLayer, channelBW, rssiThreshold: minRssi} = projectParams;
    const selectedSmartType =
      displayedAntennas.length > 0
        ? displayedAntennas
            .map((obj) => obj.type)
            .sort(sortSmartTypeString)
            .slice(-1)[0]
        : null;
    const isRssi = Validator.isNumber(minRssi);
    const rssiLevels = getRssiLevels(mapType, mapLayer, channelBW, minRssi); // signalLevels // getSignalLevels
    let labels = getColorLabels(mapType, mapLayer, [Infinity, ...rssiLevels], t, selectedSmartType, channelBW);
    if (project.name === MINISTRY_OF_ENVIRONMENTAL_PROTECTION) {
      labels = colorsLabelsInfo["powerDensity"];
    }
    console.log("labels");
    console.log(labels);

    return (
      <ControlContainer>
        <StyledModal>
          <Modal.Header>
            <Modal.Title>{isPowerDensity ? "Power Density" : colorTitle[layerType]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isRssi ? (
              <ListGroup className={"list-group-colors"}>
                {labels.map(({rssiValue, color, label}) => (
                  <ColoredListItem key={rssiValue} backcolor={color}>
                    {label}
                  </ColoredListItem>
                ))}
              </ListGroup>
            ) : (
              <LoaderSpinner />
            )}
          </Modal.Body>
        </StyledModal>
      </ControlContainer>
    );
  } catch (e) {
    console.error(e);
    return (
      <ControlContainer>
        <div>there is an error here</div>
      </ControlContainer>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    mapType: reduxState.map.mapType,
  };
}

export default connect(mapStateToProps)(ColorMapping);
