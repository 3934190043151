import Constants from "../Constants";
import { fetchAuth, handleError, navigateResponse } from "./helper";

const { DRIVETEST_URL } = Constants;


export const DriveTest = class {

    static async driveTestValidator(file) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            return await fetchAuth(DRIVETEST_URL, {
                method: 'POST',
                body:formData
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }

};