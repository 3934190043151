import Constants from "../Constants";
import {errors} from "../Constants";
import {fetchAuth, handleError, navigateResponse} from "./helper";

export const Project = class {
  static async getProject(projectId, {binsPopulated = true}) {
    try {
      if (!projectId) throw Error(errors.NO_PROJECT_ID);
      const res = await fetchAuth(
        `${Constants.REST_URL}projects/${projectId}${binsPopulated ? `?${binsPopulated ? "bins-populated=true" : ""}` : ""}`,
        {method: "GET"}
      );
      return await navigateResponse(res);
    } catch (e) {
      if (e.message === errors.NO_PROJECT_ID) return;
      handleError(e);
    }
  }

  static async addProject(projectName) {
    try {
      const res = await fetchAuth(Constants.REST_URL + "projects", {
        method: "POST",
        body: JSON.stringify({name: projectName}),
      });
      const projectResult = await navigateResponse(res);
      return {...projectResult};
    } catch (e) {
      handleError(e);
    }
  }

  static editProject(newProject) {
    return fetchAuth(Constants.REST_URL + "projects/" + newProject._id, {
      method: "PUT",
      body: JSON.stringify(newProject),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static async getProjects() {
    try {
      const res = await fetchAuth(Constants.REST_URL + "projects", {method: "GET"});
      return await navigateResponse(res);
    } catch (e) {
      handleError(e);
    }
  }

  static deleteProject(projectId) {
    return fetchAuth(Constants.REST_URL + "projects/" + projectId, {method: "DELETE"})
      .then(navigateResponse)
      .catch(handleError);
  }

  static clusterProject(projectId, mapLayer = null) {
    return fetchAuth(`${Constants.REST_URL}projects/${projectId}/cluster`, {
      method: "POST",
      body: JSON.stringify({mapLayer: mapLayer}),
    })
      .then(navigateResponse)
      .catch(handleError);
  }
};
