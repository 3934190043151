import React, {useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import Api from "../../controller/ApiManager/index";
import {openDialog, setDialogSite} from "../../store/actionCreators/general";
import {addToast} from "../../store/actionCreators/general";
import {useFetchCallback} from "../../Hooks/useFetchCallback";
import {modals} from "../../controller/Constants";
import {addProject} from "../../store/actionCreators/map";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const ModalContent = styled.div`
  width: 40vw;
  .checkbox .form-check-input {
    position: relative;
    bottom: -7px;
    margin-top: 10px;
  }
`;

const SubmitButton = styled(Button)`
  &.btn[type="submit"] {
    margin-top: 20px !important;
  }
`;

function AddProject({onClose: closeDialog, onBack, addToast, setDialogSite, addProject}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const {t} = useTranslation();
  const isChecked = useRef(null);
  const history = useHistory();

  const handleSubmit = useFetchCallback(
    async (ev) => {
      ev.preventDefault();
      const newProject = await Api.Project.addProject(name);
      addToast(`Successfully added Project "${newProject.name}" to Database!`);
      addProject({...newProject});
      if (isChecked.current.checked) {
        history.push("/projects/" + newProject._id);
        openEditProjectDialog();
      } else {
        closeDialog();
      }
    },
    [name, closeDialog],
    {
      setError: (err) => {
        console.error(err);
        closeDialog();
      },
    }
  );

  function openEditProjectDialog() {
    dispatch(openDialog(modals.EDIT_PROJECT));
  }

  return (
    <ModalContent>
      <Modal.Header closeButton>
        <div className="section-title">
          <h2>{t("Add_Project_Title")}</h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form className="form inputs-underline" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Form.Group>
                <Form.Label htmlFor="project_name" column={false}>
                  {t("Project_Name")}
                </Form.Label>
                <Form.Control type="text" name="project_name" placeholder="Last name" onChange={(ev) => setName(ev.target.value)} value={name} />
              </Form.Group>
            </div>
          </div>
          <Form.Group className="checkbox">
            <Form.Check ref={isChecked} type="checkbox" label="Go to the new project" />
          </Form.Group>
          <Form.Group className="center">
            <SubmitButton type={"submit"} variant={"primary"} className="width-100">
              {t("Add_Project")}
            </SubmitButton>
          </Form.Group>
        </Form>
      </Modal.Body>
    </ModalContent>
  );
}

export default connect(() => {}, {setDialogSite, addToast, addProject})(AddProject);
