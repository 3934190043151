import Constants from "../Constants";
import Validator from "../Validator";
import {fetchAuth, handleError, navigateResponse} from "./helper";
import {Project} from "./Project";
import {Site} from "./Site";
import {Sector} from "./Sector";
import {ProjectParams} from "./ProjectParams";
import {DriveTest} from "./driveTest";

class Api {

    static Project = Project;

    static ProjectParams = ProjectParams;

    static Site = Site;

    static Sector = Sector;

    static DriveTest = DriveTest;
    
    static async optimizeProject(projectId) {
        return await fetchAuth(`${Constants.REST_URL}projects/${projectId}/optimize`, {
            method: 'POST',
            body: JSON.stringify(projectId),
        }).then(navigateResponse).then(data => {
            return data;
        }).catch(handleError);
    }

    static async uploadImage(image) {
        if(image.size > 1000000 )
          throw Error("It is not possible to upload an image above 10 MB");
        return await fetchAuth(`${Constants.REST_URL}uploadimage/${image.name}`, { 
            method: 'POST',
            body: image,
        }).then(data => {
            return data;
        }).catch(handleError);
    }
}


class UserApi {
    static login({email, password}) {
        const emailValid = Validator.isEmail(email);
        const passwordValid = password.length >= 6;
        if (emailValid && passwordValid)
            return fetch(`${Constants.AUTH_URL}login`, {
                method: 'POST',
                body: JSON.stringify({email, password})
            }).then(navigateResponse).catch(handleError);
        else if (!emailValid && !passwordValid) {
            throw Error(`invalid email and password`)
        } else if (!emailValid && passwordValid) {
            throw Error(`invalid email`)
        } else if (emailValid && !passwordValid) {
            throw Error(`invalid password`)
        }
    }

    static register({email, password}) {
        const emailValid = Validator.isEmail(email);
        const passwordValid = password.length >= 6;
        if (emailValid && passwordValid)
            return fetch(`${Constants.AUTH_URL}register`, {
                method: 'POST',
                body: JSON.stringify({email, password})
            }).then(navigateResponse).catch(handleError);
        else if (!emailValid && !passwordValid) {
            throw Error(`invalid email and password`)
        } else if (!emailValid && passwordValid) {
            throw Error(`invalid email`)
        } else if (emailValid && !passwordValid) {
            throw Error(`invalid password`)
        }
    }

    static refreshToken({email, refreshToken}) {
        return fetch(Constants.AUTH_URL + 'refreshToken', {
            method: 'POST',
            body: JSON.stringify({email, refreshToken})
        }).then(navigateResponse).catch(handleError);
    }

    static logout(email) {
        // return fetch(Constants.AUTH_URL+'logout',{
        //     method:'POST',
        //     body:JSON.stringify({email}),
        // }).then(navigateResponse).catch(handleError)
        //todo: create a global logout...
    }
}

export default Api;
export {UserApi};