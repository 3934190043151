import React, {useCallback, useEffect, useState, useContext} from "react";
import SectorInputs from "./SectorInputs";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import Validator from "../../controller/Validator";
import Card from "react-bootstrap/Card";
import {siteModes, antennaModels, antennaConfigs} from "../../controller/models/enums";
import {useFetchCallback} from "../../Hooks/useFetchCallback";
import Api from "../../controller/ApiManager";
import {useGlobalStateOperations} from "../../Hooks/useGlobalOperations";
import {useDialogAlert} from "../../contexts/DialogAlert";
import {useProjectState} from "../../Hooks/useProject";
import Constants from "../../controller/Constants";
import AccordionContext from "react-bootstrap/AccordionContext";
import {useAccordionToggle} from "react-bootstrap";

const Div = styled.div`
  height: auto;
  width: 100%;
  margin-top: 20px;
`;

const ApplyButton = styled(Button)`
  width: 90%;
  margin: 30px 5% 0 5%;
  height: calc(100% - 30px);
`;
const SectorButton = styled.i`
  cursor: pointer;
  float: right;
  margin-top: 5px;
`;
const {predesign, postdesign} = Constants.siteModes;
const DEFAULT_SECTOR = {
  file: null,
  height: 0,
  cost: 0,
  number: null,
  technology: null,
  antennaModel: null,
  electricTilt: null,
  mechanicTilt: null,
  txLoss: 0,
  txPower: 0,
  azimuth: null,
  output: null,
  frequency: null,
  antennaConfig: antennaConfigs[0],
  EiRP: null,
  defaultValues: {
    cost: 0,
    height: 0,
    number: null,
    technology: null,
    antennaModel: null,
    electricTilt: null,
    mechanicTilt: null,
    txLoss: 0,
    txPower: 0,
    azimuth: null,
    output: null,
    frequency: null,
    antennaConfig: antennaConfigs[0],
    EiRP: null,
  },
  antennaModels: antennaModels.slice(0, 1),
  antennaPrices: {
    TYPE_1X1: 0,
    TYPE_2X2: 0,
    TYPE_4X4: 0,
    TYPE_8X8: 0,
    TYPE_8X8_STEERED: 0,
    TYPE_16X16: 0,
    TYPE_32X32: 0,
    TYPE_64X64_STEERED: 0,
  },
  inDatabase: false,
};
const getDefaultSector = (siteMode) => {
  if (!siteMode) throw Error("new Sector must include siteMode");
  return {...DEFAULT_SECTOR, siteMode};
};

function SectorListEditor({changeSite: setSiteState, siteMode, site}) {
  const {t} = useTranslation();
  const [project, setProject] = useProjectState();
  const allSectors = [...site.preDesign.sectors, ...site.postDesign.sectors];
  const [sectors, setSectors] = useState(allSectors.filter(({siteMode: mode}) => mode === siteMode));
  const [activeKey, setActiveKey] = useState(null);
  const [newSectors, setNewSectors] = useState({
    [predesign]: getDefaultSector(predesign),
    [postdesign]: getDefaultSector(postdesign),
  });
  const [alert, setAlert] = useDialogAlert();
  const {addSectorToSite} = useGlobalStateOperations();
  const resetProject = async (message) => {
    const updatedProject = await Api.Project.getProject(project._id, {binsPopulated: true});
    const updatedSite = updatedProject.sites.find(({_id}) => _id === site._id);
    const allSectors = [...updatedSite.preDesign.sectors, ...updatedSite.postDesign.sectors];
    setProject(updatedProject);
    setSiteState(updatedProject.sites.find((siteObj) => siteObj._id === site.id));
    setSectors(allSectors.filter(({siteMode: mode}) => siteMode === mode));
    setAlert({message, error: false});
  };
  const onSectorDeleted = useFetchCallback(
    async (sectorId) => {
      const toDelete = window.confirm(t("Are_You_Sure_Height"));
      if (!toDelete) return;
      await Api.Sector.deleteSector(sectorId, site._id);
      await resetProject('successfully deleted sector with id:"' + sectorId + '".');
    },
    [site, setSectors, setAlert],
    {setError: (err) => setAlert({message: err.message, error: true})}
  );

  const updateSector = useFetchCallback(
    async (sector) => {
      await Api.Sector.editSector(sector, site._id);
      if (sector.file) {
        await Api.Site.uploadFileToSector(site, sector, sector.file);
      }
      await resetProject('successfully updated sector with id:"' + sector + '".');
    },
    [site, setSectors, setAlert],
    {setError: (err) => setAlert({message: err.message, error: true})}
  );

  const newSectorChange = useCallback(
    (sector) => {
      setNewSectors({...newSectors, [siteMode]: {...newSectors[siteMode], ...sector}});
    },
    [siteMode, setNewSectors]
  );

  const onAddSector = useFetchCallback(
    async (sector) => {
      const savedSector = await Api.Sector.createSector(sector, site._id);
      setSectors((oldSectors) => [...oldSectors, savedSector]);
      addSectorToSite(site._id, savedSector);
      await resetProject(`successfully added sector with id: "${savedSector._id}".`);
    },
    [setSectors, site, siteMode],
    {setError: (err) => setAlert({error: true, message: "there were problem creating new Sector."})}
  );

  const addSectorClick = useCallback(() => {
    setNewSectors({...newSectors, [siteMode]: getDefaultSector(siteMode)});
    onAddSector({...newSectors[siteMode], driveTestFile: {}, siteMode});
  }, [onAddSector, siteMode, newSectors, setNewSectors]);

  const updateSectorDetails = useCallback(
    (sector) => {
      const updatedSectors = sectors.map((item) => (item._id === sector._id ? {...item, ...sector} : item));
      if (Validator.isSector(sector)) {
        setSectors(updatedSectors);
      }
    },
    [sectors, setSectors, newSectors]
  );

  useEffect(() => {
    if (activeKey !== null && activeKey !== -1) setActiveKey(null);
  }, [siteMode]);

  useEffect(() => {
    setSectors(allSectors.filter(({siteMode: mode}) => mode === siteMode));
  }, [siteMode, project]);

  const AddSectorButton = (
    <ApplyButton className="btn-outline-dark" onClick={addSectorClick}>
      {t("Add_Height")}
    </ApplyButton>
  );

  function ContextAwareToggle({eventKey, sector, callback, ...props}) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <Card.Header className={props.bold ? "bold" : ""} onClick={decoratedOnClick}>
        {sector.height + " Meters"} {sector.azimuth === 0 || sector.azimuth ? ` ${sector.azimuth}°` : " " + t("Omni_antenna")}{" "}
        {sector.number ? `Num. ${sector.number}` : ""}
        <SectorButton {...props} className={isCurrentEventKey ? "fa fa-minus-circle fa-lg" : "fa fa-plus-circle fa-lg"} />
      </Card.Header>
    );
  }

  return (
    <Div autoHeight={sectors.length > 0}>
      <Accordion activeKey={activeKey} onSelect={setActiveKey}>
        {sectors.map((sector, i) => {
          return (
            <Card key={sector._id}>
              <ContextAwareToggle sector={sector} eventKey={String(i)} />
              <Accordion.Collapse eventKey={String(i)}>
                <Card.Body>
                  <SectorInputs
                    sector={sector}
                    key={sector._id}
                    onSectorChange={updateSectorDetails}
                    onDelete={onSectorDeleted}
                    button={
                      <ApplyButton className="btn-outline-dark" onClick={() => updateSector(sector)}>
                        {t("Save_Sector")}
                      </ApplyButton>
                    }
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={String(sectors.length || 0)}>
            {t("Add_New_Sector")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={String(sectors.length || 0)}>
            <Card.Body>
              <SectorInputs sector={newSectors[siteMode]} key={sectors.length} onSectorChange={newSectorChange} button={AddSectorButton} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Div>
  );
}

SectorListEditor.defaultProps = {};
export default SectorListEditor;
