import {getSiteSectors, isSameLocation} from "./dataOperations";
import {errors} from "./Constants";
import {getRandomArbitrary, angleFromCoordinate} from "./common";
import {isMaplayerUseFactor} from "./mapper";

/**
 * @return {number}
 */
function calcRssiForSite(bin, parameters, correctionFactor = 0) {
  const {txPower, txLoss} = parameters;
  return Number(bin.signal) + Number(correctionFactor) + Number(txPower) - Number(txLoss);
}

export function binsMapperBestServer(sites, displayedSectors, correctionFactorCalculator, mapLayer) {
  //todo: refactor this ugly function
  if (!sites) return [];
  const activeDisplayedSectors = displayedSectors.filter((displayedSector) => displayedSector.display);

  function onlySelectedSites(site) {
    const selectedObject = activeDisplayedSectors.find((heightObj) => String(site._id) === String(heightObj.siteId));
    return Boolean(selectedObject) && Boolean(selectedObject.sectorId);
  }

  function mapToDecoratedHeight(site) {
    const releventDisplayedSectors = activeDisplayedSectors.filter((displayedSector) => String(site._id) === String(displayedSector.siteId));
    const sectorIds = releventDisplayedSectors.map(({sectorId}) => sectorId);
    const sectors = getSiteSectors(site).filter(({_id}) => sectorIds.includes(_id)); // all real sectors in the site
    return sectors.map((sector) => {
      try {
        const sectorCopy = {...sector};
        const {txPower, txLoss} = sectorCopy;
        const {antennaModel, conversion} = releventDisplayedSectors.find(({sectorId}) => sectorCopy._id === sectorId);
        sectorCopy.conversion = conversion;
        sectorCopy.currentAntenna = antennaModel;
        sectorCopy.parameters = {txPower, txLoss};
        sectorCopy.siteLocation = site.location;
        sectorCopy.site = site.displayName;
        return sectorCopy; // returns Decorated sector
      } catch (e) {
        throw e;
      }
    });
  }
  // for all bin, calc new signal
  function reduceToOneList(accmulator, sectorDecorated) {
    if (sectorDecorated.binsPlacements && sectorDecorated.binsPlacements.length === 0) throw Error(errors.NO_BINS);
    const currentPlacement = sectorDecorated.binsPlacements.find((placement) => {
      return placement.smartType === sectorDecorated.currentAntenna;
    });
    if (!currentPlacement) throw Error(errors.NO_BINS);
    currentPlacement.bins.forEach(({location, signal: signalOrigin}) => {
      const accCopy = [...accmulator]; // shallow copy
      const binIndex = accCopy.findIndex((bin) => isSameLocation(bin.location, location));
      const {txPower, txLoss} = sectorDecorated.parameters;
      const conversionFactor = sectorDecorated?.conversion?.to === "5G" ? getRandomArbitrary(-3.5, -7.5) : 0;
      const signal =
        signalOrigin +
        conversionFactor +
        (isMaplayerUseFactor(mapLayer)
          ? correctionFactorCalculator({
              antennaType: sectorDecorated.currentAntenna,
              txPower,
              txLoss,
            })
          : 0);
      const current_site_formatted = {
        site: sectorDecorated.site,
        height: sectorDecorated.height,
        location: sectorDecorated.siteLocation,
        signal,
        smartType: sectorDecorated.currentAntenna,
      };
      if (binIndex !== -1) {
        const bin = accCopy.splice(binIndex, 1)[0];
        bin.sites.push(current_site_formatted);
        bin.signal = Math.max(...bin.sites.map((site) => site.signal));
      } else {
        accmulator.push({
          sites: [current_site_formatted],
          signal,
          location: location,
          // angle: angleFromCoordinate(location.lat, location.lng,,);
        });
      }
    });
    return accmulator;
  }

  try {
    const selectedSites = sites.filter(onlySelectedSites); // sitesWithActiveSector, (atLeasetOneSectorInSiteIsActive)
    const heightsDecorated = selectedSites.flatMap(mapToDecoratedHeight); // all decorated sector that are displaed
    const bins = heightsDecorated.reduce(reduceToOneList, []);
    return bins;
  } catch (error) {
    if (error.message === errors.NO_BINS) {
      return [];
    }
    throw error;
  }
}

function outOfDate() {
  throw Error("function is out of date");
}

export function binsMapperC2I(sites, selectedHeights) {
  outOfDate();
  if (selectedHeights.length !== 2) return [];

  function getHeightList(singleSite) {
    const {height: heightMeters, type} = selectedHeights.find((selectedHeight) => singleSite._id === selectedHeight.site);
    const heights = singleSite.preDesign.sectors;
    let height = heights.find((heightObj) => {
      return heightObj.height === heightMeters;
    });
    height.site = singleSite._id;
    height.currentAntenna = type;
    height.parameters = singleSite.parameters;
    height.siteLocation = singleSite.location;
    return height;
  }

  function reduceToOneList(accmulator, heightDecorated) {
    if (heightDecorated.binsPlacements && heightDecorated.binsPlacements.length === 0) throw Error(errors.NO_BINS);
    const currentPlacement = heightDecorated.binsPlacements.find((placement) => {
      return placement.smartType === heightDecorated.currentAntenna;
    });
    if (!currentPlacement) throw Error(errors.NO_BINS);
    currentPlacement.bins.forEach((bin) => {
      const currentBinLocation = bin.location;
      const matchedBin = accmulator.find((accmsBin) => isSameLocation(accmsBin.location, currentBinLocation));
      const signal = calcRssiForSite(bin, heightDecorated.parameters);
      const current_site_formatted = {
        site: heightDecorated.site,
        height: heightDecorated.height,
        location: heightDecorated.siteLocation,
        signal,
      };
      if (matchedBin) {
        const binsSites = matchedBin.sites;
        if (sites[0]._id ? binsSites[0].site === sites[0]._id : binsSites[0].site === sites[0])
          matchedBin.sites = [...binsSites, current_site_formatted];
        else matchedBin.sites.push(current_site_formatted);
        matchedBin.signal = Math.abs(matchedBin.sites[0].signal - matchedBin.sites[1].signal);
      } else {
        accmulator.push({
          sites: [current_site_formatted],
          signal,
          location: bin.location,
        });
      }
    });

    return accmulator;
  }

  try {
    const heightList = sites.map(getHeightList);
    const reduced = heightList.reduce(reduceToOneList, []);
    return reduced.filter((bin) => bin.sites.length === 2);
  } catch (error) {
    if (error.message === errors.NO_BINS) {
      return [];
    }
    throw error;
  }
}

export function mapPrediction(predictionData, siteDetails, correctionFactor) {
  const {signal: signalArr, lat: latArr, lng: lngArr} = predictionData;
  const bins = [];
  for (let i = 0; i < signalArr.length; i++) {
    const signal = signalArr[i] + correctionFactor;
    const location = {lat: latArr[i], lng: lngArr[i]};
    const sites = [
      {
        ...siteDetails,
        signal,
      },
    ];
    bins.push({
      location,
      signal,
      sites,
    });
  }
  return bins;
}

export function mergePredictions(binPerSite) {
  return binPerSite.reduce((acc, {bins}) => {
    const accCopy = [...acc]; // shallow copy
    if (accCopy.length === 0) return bins;
    for (const bin of bins) {
      const binIndex = accCopy.findIndex(({location}) => isSameLocation(bin.location, location));
      if (binIndex !== -1) {
        const oldBin = accCopy.splice(binIndex, 1)[0]; //pop on index for performance
        oldBin.sites.push(...bin.sites);
        oldBin.signal = Math.max(oldBin.signal, bin.signal);
      } else {
        acc.push(bin);
      }
    }
    return acc;
  }, []);
}
