import {events, errors} from "../Constants";
import {application} from "../../App";
import EventEmitter from "../EventEmitter";

export async function navigateResponse(res) {
  if (res.ok) {
    const data = await res.json();
    if (data.presignedUrl) {
      return await fetch(data.presignedUrl).then(navigateResponse).catch(handleError);
    }
    return data;
  }
  if (Number(res.status) === 401) {
    throw Error(errors.UNAUTHORIZED);
  }
  console.log("Error response: ", res);
  throw Error("there were problem with the server response, status: " + res.status + "\nErrorMessage: " + (await res.json()));
}

export function userNotLoggedIn() {
  const eventName = events.REFRESH_USER_TOKEN;
  if (process.env.REACT_APP_STAGE === "dev") {
    // window.alert(eventName +' is being dispatched please see logs and add debuggers!');
  }
  const isDispatched = EventEmitter.dispatch(eventName);
  console.log(eventName + (isDispatched ? "" : " not ") + " dispatched");

  throw Error(errors.TRY_AGAIN);
}

export function handleError(err) {
  if (err.message === errors.UNAUTHORIZED) {
    return userNotLoggedIn();
  }
  console.error(err);
  throw err;
}

export function fetchAuth(url, options = {headers: {}}) {
  const storeState = application.store.getState();
  console.log({url, options, Authorization: "Bearer " + storeState.user.idToken});

  //   return fetch(url, {
  //     ...options,
  //     headers: {
  //       ...options.headers,
  //       Authorization:
  //         "eyJraWQiOiJ5cmpPS1wvUkswSHpDTUZYYm9HZUs0NVF2eUtHcW1KSUJkck9GUERrR3IxTT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3MDVlODkzMC02YzQyLTQ0NDEtOWUzZi0wNWM5ODY1Zjg5ZGYiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfWmFzNWQzUW9LIiwiY29nbml0bzp1c2VybmFtZSI6IjcwNWU4OTMwLTZjNDItNDQ0MS05ZTNmLTA1Yzk4NjVmODlkZiIsIm9yaWdpbl9qdGkiOiI2NjU4ZmExYi03NTQ5LTQyZGYtODFkOS1lZWE5Yjk2ODQ4YTkiLCJhdWQiOiI3a2VpZDc4bGNmcG1oZGFycDg3NGpqMWJzbiIsImV2ZW50X2lkIjoiNDY5ZDMwZjUtMDA2Yi00MzY0LWE4YjMtNmEwZDEwYjg4NDM0IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2Mzk1NjY2MTksImV4cCI6MTYzOTU3MDIxOSwiaWF0IjoxNjM5NTY2NjE5LCJqdGkiOiI0ODBkOGY5MC0zNmY0LTQ0OTQtOTA0ZS04MmFlNmIyOTMyZDUiLCJlbWFpbCI6ImFkbWluQGZseWNvbW0uY28ifQ.DwUlYr8tKmdxImJT4Z9-mDgJTHtKfLtB5OFYxWXyvBCmuAdzNejWmIB83fY_nfIL4AxAlCdqzPiIDa09uMO9SVVfAtS-18ImFYXBb-KcSIQj_O1BUwUXMlLXmbq_vg4IwWuAYy1xJtgWDX9giv7KNWdszxR54iylTT2pu17KjVd3QggsdozTcDs8MqflZSIYjYQr450YXzmpjvumMefd09lXYBnGuIPH4oBYVH4CB2IuHLgzDo64jRT-PjMYN2I8bf0ydEb68Qzq2FmVcxP05oxJUCUgElv4BPIn__48GEtnfpzQad-ZLkwqZ9mh-yF7wmphCLvtV29PzkxhWr5agA",
  //     },
  //   });
  return fetch(url, {...options, headers: {...options.headers, Authorization: "Bearer " + storeState.user.idToken}});
}
